import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, } from "react";
import classNames from "classnames";
export const Timeline = forwardRef(({ className, as: Component = "a", items, activeIndex, ...rest }, ref) => {
    return (_jsx("ol", { className: classNames("cvr-timeline", className), ...rest, ref: ref, children: items.map(({ label, id, className: itemClassName, ...item }, index) => {
            return (_jsx("li", { className: classNames("cvr-timeline-item", {
                    completed: activeIndex > index,
                    active: activeIndex === index,
                }), children: _jsxs(Component, { className: classNames("cvr-timeline--link", itemClassName), ...item, children: [_jsx("span", { className: "cvr-timeline--badge", children: activeIndex <= index && index + 1 }), _jsx("span", { children: label })] }) }, id));
        }) }));
});
