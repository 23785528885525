import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
export const TableHeader = ({ columns, headerRowBackgroundClass, }) => {
    return (_jsx("thead", { className: classNames("text-sm", headerRowBackgroundClass ?? "bg-gray-50"), children: _jsx("tr", { children: columns?.map((column, index) => (_jsx("th", { className: classNames("whitespace-nowrap", "px-4 py-3 text-start text-sm font-medium", {
                    hidden: column.hidden,
                }), style: {
                    width: column.widthPercentage && `${column.widthPercentage}%`,
                }, children: column.header }, `header-cell-${index}`))) }) }));
};
export default TableHeader;
