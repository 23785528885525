import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "classnames";
import { forwardRef, useId } from "react";
export const Picker = forwardRef(({ className, children, type, ...rest }, ref) => {
    const id = useId();
    return (_jsxs(_Fragment, { children: [_jsx("input", { className: "cvr-picker sr-only", type: type, ...rest, ref: ref, id: id }), _jsxs("label", { htmlFor: id, className: classNames("cvr-picker-label", className), children: [children, _jsx("span", { className: classNames({
                            "cvr-picker-check": type === "checkbox",
                            "cvr-picker-radio": type === "radio",
                        }) })] })] }));
});
