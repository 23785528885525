import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import classNames from "classnames";
export const Button = forwardRef(({ children, className, variant = "primary", as: Component = "button", ...rest }, ref) => {
    return (_jsx(Component, { className: classNames("cvr-button", className, {
            "cvr-button--primary": variant?.startsWith("primary"),
            "cvr-button--secondary": variant?.startsWith("secondary"),
            "cvr-button--tertiary": variant?.startsWith("tertiary"),
            "cvr-button--large": variant?.endsWith("large"),
        }), ref: ref, ...rest, children: children }));
});
