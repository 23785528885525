import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { forwardRef } from "react";
import { Button } from "../Button";
export const LinkPanel = forwardRef(({ as: Component = "a", children, className, size, imageUrl, ...rest }, ref) => (_jsx(Component, { style: {
        backgroundImage: `url('${imageUrl}')`,
    }, className: classNames("cvr-link-panel", className, {
        "cvr-link-panel--small": size?.startsWith("small"),
    }), ...rest, ref: ref, children: _jsx(Button, { as: "span", className: classNames("cvr-link-panel-button", className, {
            "cvr-link-panel-button--large": !size?.startsWith("small"),
        }), children: children }) })));
